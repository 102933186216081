import { addEventDelegate } from "@jeffcarbine/premmio/public/modules/eventDelegate/eventDelegate.js";

// function toggleCart(button) {
//   const outerCart = document.getElementById("outerCart"),
//     navToggle = document.getElementById("navToggle");

//   if (outerCart.classList.contains("open")) {
//     outerCart.classList.remove("open");
//     button.classList.remove("open");

//     setTimeout(function () {
//       navToggle.classList.remove("sub");
//       if (usermenu !== null) {
//         usermenu.classList.remove("sub");
//       }
//     }, 500);
//   } else {
//     button.classList.add("open");
//     outerCart.classList.add("open");
//     navToggle.classList.add("sub");
//     if (usermenu !== null) {
//       usermenu.classList.add("sub");
//     }
//   }
// }

// export { toggleCart };

// addEventDelegate("click", "#cartToggle", toggleCart);

// const closeCart = () => {
//   const cartToggle = document.querySelector("#cartToggle");

//   toggleCart(cartToggle);
// };

// addEventDelegate("click", "#outerCart .collapser .clickOutside", closeCart);

const outerNav = document.querySelector("#outerNav");

function setNavBackground(scrollPos) {
  const _100vh = window.innerHeight;

  if (scrollPos > _100vh) {
    outerNav.classList.add("scrolled");
  } else {
    outerNav.classList.remove("scrolled");
  }
}

addEventDelegate("scroll", window, setNavBackground);

// parallax background
function backgroundParallax(scroll) {
  var parallax1, parallax2, parallax3, parallax4;

  if (innerWidth > 700) {
    parallax1 = scroll / 150;
    parallax2 = scroll / 120;
    parallax3 = scroll / 100;
    parallax4 = scroll / 400;
  } else {
    parallax1 = scroll / 300;
    parallax2 = scroll / 240;
    parallax3 = scroll / 200;
    parallax4 = scroll / 800;
  }

  let parallaxElements = document.querySelectorAll(
    "body, main section, #outerNav.inner:not([data-campaign-header])"
  );

  parallaxElements.forEach(function (element) {
    element.style.backgroundPositionX = parallax4 + "%, -" + parallax3 + "%";
    element.style.backgroundPositionY = parallax1 + "%, " + parallax2 + "%";
  });
}

addEventDelegate("scroll", window, backgroundParallax);

// function collapseInnerHeader(scroll) {
//   let elements = document.querySelectorAll(".main-logo.lg, .collapser.inner");

//   elements.forEach(function (element) {
//     if (scroll > 20) {
//       element.classList.add("collapsed");
//     } else {
//       element.classList.remove("collapsed");
//     }
//   });
// }

// addEventDelegate("scroll", window, collapseInnerHeader);

// if (document.getElementById("quantity")) {
//   document.getElementById("quantity").onchange = function () {
//     this.form.submit();
//   };
// }

// // function to handle loading of images
// function loadImage(img) {
//   img.src = img.dataset.src;
// }

// function lazyLoad(selector) {
//   let images = document.querySelectorAll(
//     "img" + selector + ":not([data-loaded])"
//   );

//   images.forEach((image) => {
//     image.src = image.dataset.src;
//     image.dataset.loaded = true;
//   });
// }

// const loadAudio = (button) => {
//   // get the next sibling, which is the audio element
//   const audio = button.nextElementSibling,
//     src = audio.dataset.src;

//   audio.src = src;
//   audio.style.display = "block";
//   button.style.display = "none";

//   audio.play();
// };

// addEventDelegate("click", "button.loadAudio", loadAudio);

// const setCartLoading = () => {
//   const cart = document.querySelector("#cart");
//   cart.className = "loading";
// };

// // fetch the cart
// const fetchCart = () => {
//   // make sure the cart is loading
//   setCartLoading();

//   // get onscreen elements
//   const lineItems = document.querySelector(".lineItems");
//   lineItems.replaceChildren();

//   xhr({ path: "/shop/fetch_cart", success: renderCart });
// };

// export { fetchCart };

// const renderCart = (request) => {
//   // get the data returned to us
//   const response = JSON.parse(request.response),
//     lineItemsData = response.cart.lineItems;

//   // get some onscreen elements
//   const cart = document.querySelector("#cart"),
//     cartToggleItemCount = document.querySelector("#cartToggle .itemCount"),
//     checkout = document.querySelector(".checkoutBtn .btn");

//   // set the checkout url
//   checkout.href = response.cart.webUrl;

//   // set default zero for itemCount
//   let itemCount = 0;

//   // set the pricing
//   const pricing_subtotal = document.querySelector(".cartPricing span.subtotal"),
//     pricing_total = document.querySelector(".cartPricing span.total"),
//     pricing_discount = document.querySelector(".cartPricing span.discount");

//   // set the value for the subtotal
//   pricing_subtotal.textContent = formatCurrency(
//     response.cart.subtotalPrice.amount
//   );

//   // check for discounts
//   const calculatedTotal = response.cart.lineItemsSubtotalPrice.amount,
//     total = response.cart.subtotalPrice.amount;

//   if (calculatedTotal !== total) {
//     const diff = calculatedTotal - total;

//     pricing_total.textContent = formatCurrency(calculatedTotal);
//     pricing_discount.textContent = "Discount: -" + formatCurrency(diff);
//   } else {
//     pricing_total.style.display = "none";
//     pricing_discount.style.display = "none";
//   }

//   // get the lineItems list
//   const lineItems = document.querySelector(".lineItems");

//   // check to see if we have lineItems or not
//   if (lineItemsData.length === 0) {
//     // then we have an empty cart
//     cart.className = "empty";
//   } else {
//     cart.className = "";
//     // loop through the lineItems
//     for (let i = 0; i < lineItemsData.length; i++) {
//       const item = lineItemsData[i];

//       const lineItem = renderTemplate({
//         tagName: "li",
//         class: "lineItem",
//         children: [
//           {
//             class: "image",
//             children: [
//               {
//                 tagName: "img",
//                 src: item.variant.src,
//               },
//             ],
//           },
//           {
//             class: "content",
//             children: [
//               {
//                 tagName: "span",
//                 class: "productTitle",
//                 children: [
//                   {
//                     tagName: "span",
//                     textContent: item.title,
//                   },
//                 ],
//               },
//               {
//                 class: "quantity",
//                 children: [
//                   {
//                     class: "center",
//                     children: [
//                       {
//                         class: "quantityContainer",
//                         children: [
//                           {
//                             tagName: "button",
//                             class: "modifyQuantity",
//                             "data-quantity": item.quantity - 1,
//                             "data-item-id": item.id,
//                             children: [
//                               {
//                                 icon: minus,
//                               },
//                             ],
//                           },
//                           {
//                             tagName: "span",
//                             class: "lineItemQuantity",
//                             textContent: item.quantity,
//                           },
//                           {
//                             tagName: "button",
//                             class: "modifyQuantity",
//                             "data-quantity": item.quantity + 1,
//                             "data-item-id": item.id,
//                             children: [
//                               {
//                                 icon: plus,
//                               },
//                             ],
//                           },
//                         ],
//                       },
//                       {
//                         tagName: "button",
//                         class: "modifyQuantity delete",
//                         "aria-label": "Remove " + item.title,
//                         "data-quantity": 0,
//                         "data-item-id": item.id,
//                         children: [
//                           {
//                             icon: trash,
//                           },
//                         ],
//                       },
//                     ],
//                   },
//                 ],
//               },
//               {
//                 tagName: "span",
//                 class: "price",
//                 textContent: "$" + item.variant.price.amount,
//               },
//             ],
//           },
//         ],
//       });

//       lineItems.appendChild(lineItem);

//       // and add the quantity to the itemCount
//       itemCount += item.quantity;
//     }
//   }

//   // now set the itemCount to theh cartToggle
//   cartToggleItemCount.textContent = itemCount;
// };

// fetchCart();

// const handleLineItemModification = (button) => {
//   // set the cart to loading
//   setCartLoading();

//   // get the values from the button

//   const data = {
//     itemId: button.dataset.itemId,
//     quantity: button.dataset.quantity,
//   };

//   xhr({ path: "/shop/modify_line_item", body: data, success: fetchCart });
// };

// addEventDelegate("click", "button.modifyQuantity", handleLineItemModification);

// // expand episode description, here because it is on both
// // the episodes page and the index
// const expandEpisodeDescription = (button) => {
//   const parent = button.parentNode,
//     description = parent.querySelector(".description");

//   description.classList.add("expanded");
//   button.classList.add("hidden");
// };

// addEventDelegate(
//   "click",
//   "button.descriptionExpander",
//   expandEpisodeDescription
// );

// function addLineItemToCart(item) {
//   // copy the line item element
//   let newLineItem = lineItem.cloneNode(),
//     image = newLineItem.querySelector("img"),
//     title = newLineItem.querySelector(".title"),
//     variantTitle = newLineItem.querySelector(".variantTitle"),
//     incrementForm = newLineItem.querySelector("form.increment"),
//     decrementForm = newLineItem.querySelector("form.decrement"),
//     removeForm = newLineItem.querySelector("form.remove"),
//     currentQuantity = newLineItem.querySelector(".currentQuantity"),
//     quantity = newLineItem.querySelector(".quantity"),
//     price = newLineItem.querySelector(".lineItemPrice");
// }

// addLineItemToCart();

// function parallaxSplash(scrollPos) {
//   const _100vh = window.innerHeight;

//   const foregrounds = document.querySelectorAll(".foreground"),
//     midgrounds = document.querySelectorAll(".midground");

//   if (scrollPos > 0 && scrollPos < _100vh) {
//     foregrounds.forEach((foreground) => {
//       let offset = scrollPos / 12;
//       foreground.style.transform = "translateY(" + offset + "px)";
//     });

//     midgrounds.forEach((midground) => {
//       let offset = 0 - scrollPos / 24;
//       midground.style.transform = "translateY(" + offset + "px)";
//     });
//   } else if (scrollPos === 0) {
//     foregrounds.forEach((foreground) => {
//       foreground.style.removeProperty("transform");
//     });

//     midgrounds.forEach((midground) => {
//       midground.style.removeProperty("transform");
//     });
//   }
// }

// addEventDelegate("scroll", window, parallaxSplash);
